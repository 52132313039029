<template>
	<div v-if="loaded" class="opacity-page">
		<div class="settings-content">
			<div class="list-crud row-space-tbf">
				<div class="space-left"></div>
				<div class="content settings">
					<div class="crud" id="list-items">
						<div class="box" v-if="viewCrud == 'create'">
							<div class="header">
								<div class="icon-header"><icon-settings /></div>
								<div class="name">{{ $t('nomenclature.settings.update') }}</div>
							</div>
							<div class="form">
								<div class="input-group settings">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.settings.' + meal_ticket.code) }}</label>
                                        <div v-if="$v.meal_ticket.value.$model == ''" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.meal_ticket.value.$model == ''}">
										<div class="icon-right" v-if="meal_ticket.value != ''" @click.stop="meal_ticket.value = ''"><icon-close class="icon-clear" /></div>
										<input type="number" min="0" value="0" step=".01" pattern="^\d*(\.\d{0,2})?$" :placeholder="$t('nomenclature.settings.meal_ticket_value-ph')" class="input-text no-icon" v-model="meal_ticket.value">
									</div>
								</div>
                                <div class="input-group settings">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.settings.' + automatic_complete_days_in_ticket.code) }}</label>
                                    </div>
                                    <div class="input-box bor-0">
                                        <div class="checkbox-list">
                                            <div class="checkbox-item m-4" @click="automatic_complete_days_in_ticket.value = '0'" v-bind:class="{active: automatic_complete_days_in_ticket.value == 0}">
                                                <div class="name">{{ $t('create-user.no') }}</div>
                                            </div>
                                            <div class="checkbox-item m-4" @click="automatic_complete_days_in_ticket.value = '1'" v-bind:class="{active: automatic_complete_days_in_ticket.value == 1}">
                                                <div class="name">{{ $t('create-user.yes') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group settings">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.settings.' + salary_payment_day.code) }}</label>
                                        <div v-if="$v.salary_payment_day.value.$model == ''" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.salary_payment_day.value.$model == ''}">
										<div class="icon-right" v-if="salary_payment_day.value != ''" @click.stop="salary_payment_day.value = ''"><icon-close class="icon-clear" /></div>
										<input type="text" min="1" maxlength="2" max="31" onkeypress="if(parseInt(this.value)>2){this.value=this.max;return false;};return event.charCode >= 48 && event.charCode <= 57" :placeholder="$t('nomenclature.settings.salary_payment_day-ph')" class="input-text no-icon" v-model="salary_payment_day.value">
									</div>
								</div>
                                <div class="input-group settings">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.settings.' + co_calculation_option.code) }}</label>
                                        <div v-if="$v.selectedCoCalculation.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box bg-white" v-bind:class="{has_error: $v.selectedCoCalculation.$error}">
                                        <div class="icon-left"><icon-calculator /></div>
                                        <div class="icon-right" v-if="selectedCoCalculation != ''" @click.stop="selectedCoCalculation = ''"><icon-close class="icon-clear" /></div>
                                        <multiselect 
                                            v-model="selectedCoCalculation"
                                            v-bind:class="{populate: selectedCoCalculation != ''}"
                                            class="select-tags-tbf"
                                            :options="coCalculationOptions"
                                            :allow-empty="true"
                                            :show-labels="false"
                                            track-by="id" 
                                            label="label"
                                            @input="co_calculation_option.value = $event.code"
                                            >
                                            <template slot="placeholder" slot-scope="props">
                                                <span class="text">
                                                    {{ $t('salary.benefits.calcul-type-ph') }}
                                                </span>
                                            </template>
                                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                                        </multiselect>
                                    </div>
                                </div>
								<div class="input-group settings">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.settings.' + timesheet_calculation_every_time.code) }}</label>
                                    </div>
                                    <div class="input-box bor-0">
                                        <div class="checkbox-list">
                                            <div class="checkbox-item m-4" @click="timesheet_calculation_every_time.value = '0'" v-bind:class="{active: timesheet_calculation_every_time.value == 0}">
                                                <div class="name">{{ $t('create-user.no') }}</div>
                                            </div>
                                            <div class="checkbox-item m-4" @click="timesheet_calculation_every_time.value = '1'" v-bind:class="{active: timesheet_calculation_every_time.value == 1}">
                                                <div class="name">{{ $t('create-user.yes') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group settings">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.settings.' + caen.code) }}</label>
                                        <div v-if="$v.caen.value.$model == ''" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.caen.value.$model == ''}">
										<div class="icon-right" v-if="caen.value != ''" @click.stop="caen.value = ''"><icon-close class="icon-clear" /></div>
										<input type="text" min="1" maxlength="4" max="9999" onkeypress="return event.charCode >= 48 && event.charCode <= 57" :placeholder="$t('nomenclature.settings.caen-ph')" class="input-text no-icon" v-model="caen.value">
									</div>
								</div>
								<div class="input-group settings">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.settings.' + retain_CAM.code) }}</label>
                                    </div>
                                    <div class="input-box bor-0">
                                        <div class="checkbox-list">
                                            <div class="checkbox-item m-4" @click="retain_CAM.value = '0'" v-bind:class="{active: retain_CAM.value == 0}">
                                                <div class="name">{{ $t('create-user.no') }}</div>
                                            </div>
                                            <div class="checkbox-item m-4" @click="retain_CAM.value = '1'" v-bind:class="{active: retain_CAM.value == 1}">
                                                <div class="name">{{ $t('create-user.yes') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
								<div class="input-group settings">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.settings.' + national_health_insurance_agency.code) }}</label>
                                        <div v-if="$v.selectedNationalHealthInsuranceAgency.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box bg-white" v-bind:class="{has_error: $v.selectedNationalHealthInsuranceAgency.$error}">
                                        <div class="icon-left"><icon-medical /></div>
                                        <div class="icon-right" v-if="selectedNationalHealthInsuranceAgency != ''" @click.stop="selectedNationalHealthInsuranceAgency = ''"><icon-close class="icon-clear" /></div>
                                        <multiselect 
                                            v-model="selectedNationalHealthInsuranceAgency"
                                            v-bind:class="{populate: selectedNationalHealthInsuranceAgency != ''}"
                                            class="select-tags-tbf"
                                            :options="optionsHealthInsurance"
                                            :allow-empty="true"
                                            :show-labels="false"
                                            track-by="id" 
                                            label="label"
                                            @input="national_health_insurance_agency.value = selectedNationalHealthInsuranceAgency.label"
                                            >
                                            <template slot="placeholder" slot-scope="props">
                                                <span class="text">
                                                    {{ $t('nomenclature.settings.national_health_insurance_agency-ph') }}
                                                </span>
                                            </template>
                                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="input-group settings">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.settings.' + director_name.code) + ' / ' + $t('nomenclature.settings.function') }}</label>
                                        <div v-if="$v.director_name.value.$model == ''" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box bg-white mr-4" v-bind:class="{has_error: $v.director_name.value.$model == ''}">
										<div class="icon-right" v-if="director_name.value != ''" @click.stop="director_name.value = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('nomenclature.settings.director_name-ph')" class="input-text no-icon" v-model="director_name.value">
									</div>
                                    <div class="input-box bg-white ml-4" v-bind:class="{has_error: $v.director_function.value.$model == ''}">
										<div class="icon-right" v-if="director_function.value != ''" @click.stop="director_function.value = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('nomenclature.settings.function-ph')" class="input-text no-icon" v-model="director_function.value">
									</div>
								</div>
                                <div class="input-group settings">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.settings.' + accountant_name.code) + ' / ' + $t('nomenclature.settings.function') }}</label>
                                        <div v-if="$v.accountant_name.value.$model == ''" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box bg-white mr-4" v-bind:class="{has_error: $v.accountant_name.value.$model == ''}">
										<div class="icon-right" v-if="accountant_name.value != ''" @click.stop="accountant_name.value = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('nomenclature.settings.accountant_name-ph')" class="input-text no-icon" v-model="accountant_name.value">
									</div>
                                    <div class="input-box bg-white ml-4" v-bind:class="{has_error: $v.accountant_function.value.$model == ''}">
										<div class="icon-right" v-if="accountant_function.value != ''" @click.stop="accountant_function.value = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('nomenclature.settings.function-ph')" class="input-text no-icon" v-model="accountant_function.value">
									</div>
								</div>
                                 <div class="input-group settings">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.settings.' + payroll_responsible_name.code) + ' / ' + $t('nomenclature.settings.function') }}</label>
                                        <div v-if="$v.payroll_responsible_name.value.$model == ''" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box bg-white mr-4" v-bind:class="{has_error: $v.payroll_responsible_name.value.$model == ''}">
										<div class="icon-right" v-if="payroll_responsible_name.value != ''" @click.stop="payroll_responsible_name.value = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('nomenclature.settings.payroll_responsible_name-ph')" class="input-text no-icon" v-model="payroll_responsible_name.value">
									</div>
                                    <div class="input-box bg-white ml-4" v-bind:class="{has_error: $v.payroll_responsible_function.value.$model == ''}">
										<div class="icon-right" v-if="payroll_responsible_function.value != ''" @click.stop="payroll_responsible_function.value = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('nomenclature.settings.function-ph')" class="input-text no-icon" v-model="payroll_responsible_function.value">
									</div>
								</div>
								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
									<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')">
										<div class="loader"></div>
										<span class="text">{{ $t('general.update') }}</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
	</div>
	<loader-settings-page v-else/>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconSettings from '../../Icons/Settings'
    import IconCalculator from '../../Icons/Calculator'
	import IconMedical from '../../Icons/Medical'
	import LoaderSettingsPage from '../../PagesLoaders/SettingsNomenclature'
	import { required } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconClose,
			IconEdit,
			IconTrash,
			IconSettings,
            IconCalculator,
			IconMedical,
			LoaderSettingsPage
		},
		data() {
			return {
				loaded: false,
				viewCrud: 'create',
                coCalculationOptions: {},
                selectedCoCalculation: '',
                meal_ticket: {},
                calcul_meal_tickets: {},
                salary_payment_day: {},
                co_calculation_option: {},
                automatic_complete_days_in_ticket: {},
                caen: {},
				optionsHealthInsurance: [],
				retain_CAM: {},
				selectedNationalHealthInsuranceAgency: '',
				national_health_insurance_agency: {},
                director_name: {},
                director_function: {},
                accountant_name: {},
                accountant_function: {},
                payroll_responsible_name: {},
                payroll_responsible_function: {},
				timesheet_calculation_every_time: {},
				error_message: '',
			}
		},
		async mounted(){
            await this.getPayrollSettings()
			await this.getHealthInusranceData()
            await this.getCoCalculationOptions()
			await this.getRetentions()

			setTimeout(() => {
				var title = this.$t('nomenclature-sidebar.settings');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshRetentionsNomenclature', () => {
				this.viewCrud = 'create';
				this.getRetentions()
			})
		},
		validations: {
            meal_ticket:{
                value: { required }
            },
            salary_payment_day: {
                value: { required }
            },
            selectedCoCalculation: {required},
            caen: {
                value: { required }
            },
			selectedNationalHealthInsuranceAgency: {required},
            director_name: {
                value: { required }
            },
            director_function: {
                value: { required }
            },
            accountant_name: {
                value: { required }
            },
            accountant_function: {
                value: { required }
            },
            payroll_responsible_name: {
                value: { required }
            },
            payroll_responsible_function: {
                value: { required }
            },
		},
		methods: {
            async getPayrollSettings() {
                await axios.get(this.$auth.user().instance.id + `/payroll-settings`).then(({data}) => {
                    this.meal_ticket = data.data.find(el => el.code == 'meal_ticket_value')
                    this.calcul_meal_tickets = data.data.find(el => el.code == 'automatic_meal_ticket_calculation')
                    this.salary_payment_day = data.data.find(el => el.code == 'salary_payment_day')
                    this.co_calculation_option = data.data.find(el => el.code == 'CO_calculation_option')
                    this.automatic_complete_days_in_ticket = data.data.find(el => el.code == 'automatic_complete_days_in_ticket')
                    this.caen = data.data.find(el => el.code == 'caen')
					this.national_health_insurance_agency = data.data.find(el => el.code == 'national_health_insurance_agency')
					this.retain_CAM = data.data.find(el => el.code == 'retain_CAM')
                    this.director_name = data.data.find(el => el.code == 'director_name')
                    this.director_function = data.data.find(el => el.code == 'director_function')
                    this.accountant_name = data.data.find(el => el.code == 'accountant_name')
                    this.accountant_function = data.data.find(el => el.code == 'accountant_function')
                    this.payroll_responsible_name = data.data.find(el => el.code == 'payroll_responsible_name')
                    this.payroll_responsible_function = data.data.find(el => el.code == 'payroll_responsible_function')
					this.timesheet_calculation_every_time = data.data.find(el => el.code == 'timesheet_calculation_every_time')
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
            },
            async getCoCalculationOptions() {
                await axios.get(`/annual-leave-bases`).then(({data}) => {
                    this.coCalculationOptions = data.data
                    this.selectedCoCalculation = this.coCalculationOptions.find(el => el.code == this.co_calculation_option.value)
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
            },
			async getHealthInusranceData(){
                await axios.get(`${this.$auth.user().instance.id}/contract-form-noms`)
				.then(({data}) => {
                    this.optionsHealthInsurance = data.data.national_health_insurance_agencies
					this.selectedNationalHealthInsuranceAgency = this.national_health_insurance_agency.value ? this.optionsHealthInsurance.find(el => el.label == this.national_health_insurance_agency.value) : ''
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
            },
			async getRetentions(){
				await axios.get(this.$auth.user().instance.id + '/retentions')
				.then(({data}) => {
					this.retentions = data.data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName)
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';
                
				this.$v.$touch()
				if(!this.$v.$invalid){
					let objData = {settings: []}
                    objData.settings.push(this.meal_ticket)
                    objData.settings.push(this.calcul_meal_tickets)
                    objData.settings.push(this.salary_payment_day)
                    objData.settings.push(this.co_calculation_option)
                    objData.settings.push(this.automatic_complete_days_in_ticket)
                    objData.settings.push(this.caen)
					objData.settings.push(this.retain_CAM)
					objData.settings.push(this.national_health_insurance_agency)
                    objData.settings.push(this.director_name)
                    objData.settings.push(this.director_function)
                    objData.settings.push(this.accountant_name)
                    objData.settings.push(this.accountant_function)
                    objData.settings.push(this.payroll_responsible_name)
                    objData.settings.push(this.payroll_responsible_function)

					this.updatePayrollSettings(objData, btnSubmit, btnSubmitText, btnSubmitLoader)

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			
			updatePayrollSettings(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(this.$auth.user().instance.id + `/payroll-settings`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.update')
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
		}
	};
</script>

<style lang="scss">
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .content{
    &.settings{
        justify-content: center;
        .multiselect__single{
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-weight: normal;
            white-space: pre-wrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        #list-items{
            flex: 0 0 70%;
             .input-group{
                &.settings {
                    display: flex;
                    .mr-4{
                        margin-right: 4px;
                    }
                    .ml-4{
                        margin-left: 4px;
                    }
                    .label-header {
                        flex: 0 0 30%;
                        margin-bottom: 0;
                        min-width: 150px;
                        position: relative;
                        .error-msg{
                            position: absolute;
                            top: 0;
                        }
                        .label{
                            font-weight: 400;
                        }
                    }
                    .input-box{
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>